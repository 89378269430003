var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import ModalMerchiCheckout from './ModalMerchiCheckout';
import { doAddCartItem, doToggleCartOpen } from 'merchi_cart';
import MerchiProductForm from 'merchi_product_form';
import ProductImages from './ProductImages';
import ProductInformation from './ProductInformation';
import { Col, Row } from 'reactstrap';
import { getNextBackendUri } from '../utilities';
var BACKEND_URI = getNextBackendUri();
function PublicProductWithMerchiCheckout(_a) {
    var allowAddToCart = _a.allowAddToCart, currentUser = _a.currentUser, hideCalculatedPrice = _a.hideCalculatedPrice, hideCountry = _a.hideCountry, hideDomainName = _a.hideDomainName, hideDrafting = _a.hideDrafting, hideInfo = _a.hideInfo, hidePreview = _a.hidePreview, hideProductType = _a.hideProductType, hideTitle = _a.hideTitle, productJson = _a.productJson, redirectAfterSuccessUrl = _a.redirectAfterSuccessUrl, redirectAfterQuoteSuccessUrl = _a.redirectAfterQuoteSuccessUrl, redirectWithValue = _a.redirectWithValue, singleColumn = _a.singleColumn;
    var _b = __read(React.useState(productJson || {}), 2), product = _b[0], setProduct = _b[1];
    var _c = __read(React.useState(false), 2), isOpen = _c[0], setIsOpen = _c[1];
    var _d = __read(React.useState({}), 2), invoice = _d[0], setInvoice = _d[1];
    var defaultJob = productJson && productJson.defaultJob || {};
    var _e = __read(React.useState(__assign({ client: currentUser || {} }, defaultJob)), 2), job = _e[0], setJob = _e[1];
    var _f = __read(React.useState(false), 2), isBuyNow = _f[0], setIsBuy = _f[1];
    function openCheckoutModal(_job, isBuyNow) {
        setJob(_job);
        setIsOpen(true);
        setIsBuy(isBuyNow);
    }
    var columnClass = singleColumn ? '12' : '6';
    function addToCart(jobJson) {
        doAddCartItem(jobJson, function () { return doToggleCartOpen(); }, console.error);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { style: { margin: 0 } },
            !hideTitle && !hideCountry && (React.createElement("link", { rel: 'stylesheet', href: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/css/flag-icons.min.css' })),
            React.createElement(Col, { lg: columnClass },
                !hidePreview && product.id && React.createElement(ProductImages, { product: product }),
                !hideInfo && product.id && React.createElement(ProductInformation, { product: product })),
            React.createElement(Col, { lg: columnClass },
                React.createElement(MerchiProductForm, { apiUrl: "".concat(BACKEND_URI, "v6/"), allowAddToCart: true, hideCalculatedPrice: hideCalculatedPrice, hideCountry: hideCountry, hideDomainName: hideDomainName, hideProductType: hideProductType, hideTitle: hideTitle, initProduct: product, onAddToCart: function (jobJson) { return addToCart(jobJson); }, onBuyNow: function (job) { return openCheckoutModal(job, true); }, onGetQuote: function (job) { return openCheckoutModal(job, false); } }))),
        React.createElement(ModalMerchiCheckout, { currentUser: currentUser, hideDrafting: !!hideDrafting, includeDomainSignup: false, invoice: invoice, isBuyNowRequest: isBuyNow, isOpen: isOpen, job: job, product: product, redirectAfterSuccessUrl: redirectAfterSuccessUrl, redirectAfterQuoteSuccessUrl: redirectAfterQuoteSuccessUrl, redirectWithValue: redirectWithValue, setInvoice: setInvoice, setJob: setJob, toggleMerchiCheckout: function () { return setIsOpen(!isOpen); } })));
}
export default PublicProductWithMerchiCheckout;
